//##  Direction
//
// Scripting direction

$direction:  						ltr !default;

//##  Colors
$color-blue:            			#009dc7 !default;

// Background Color(Dashboard Panel)
$panelBackground: 					#e9edef !default;

// Bootstrap brand colors
$brand-default:         			#c6c6c6 !default;
$brand-primary:         		 	#3598dc !default;
$brand-success:         		 	#26A69A !default;
$brand-info:            		 	#89C4F4 !default;
$brand-warning:         		 	#ff5722 !default;
$brand-danger:          		 	#F3565D !default;

//ChicoOne specific Colors
$brand-lightgrey:                   #f1f1f1 !default;
$brand-midgrey:                     #e5e5e5 !default;
$brand-grey:                        #e5e5e5 !default;

//Chicoone Text States
$chico-fa-icon-active:              $brand-success;
$chico-fa-icon-danger:              $brand-danger;
$chico-fa-icon-inactive:            #c7c7c7 !default;
$chico-but-green:                   #1BBC9B !default;
$chico-but-red:                     #cb5a5e !default;
$chico-but-lightgrey:               #E2E2E2 !default;
$chico-but-grey:                    #cccccc !default;
$chico-text-diabled:                #D0D0D0 !default;

// Bootstrap Components states
$state-default-text:             	#666666 !default;
$state-default-bg:           	 	$brand-default !default;
$state-default-border:       	 	darken(adjust-hue($state-default-bg, -10), 5%) !default;

$state-primary-text:          	 	lighten($brand-primary, 50%) !default;
$state-primary-bg:        		 	$brand-primary !default;
$state-primary-border:    	 	 	darken(adjust-hue($state-primary-bg, -10), 5%) !default;

$state-success-text:             	lighten($brand-success, 50%) !default;
$state-success-bg:               	$brand-success !default;
$state-success-border:           	darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                	lighten($brand-info, 50%) !default;
$state-info-bg:                  	$brand-info !default;
$state-info-border:              	darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             	lighten($brand-info, 50%) !default;
$state-warning-bg:               	$brand-warning !default;
$state-warning-border:           	darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              	lighten($brand-danger, 50%) !default;
$state-danger-bg:                	$brand-danger !default;
$state-danger-border:            	darken(adjust-hue($state-danger-bg, -10), 5%) !default;

//scg
$scg-color-red:                     #f36a5a;
$scg-color-yellow:                  #f3c200;
$scg-color-green:                   #26A69A;
$scg-color-grey:                    #E5E5E5;

// Bootstrap Buttons

$btn-font-weight:                	normal !default;

$btn-default-color:              	#666666 !default;
$btn-default-bg:                 	#E6E6E6 !default;
$btn-default-border:             	#ccc !default;

$btn-primary-color:              	#fff !default;
$btn-primary-bg:                 	$brand-primary !default;
$btn-primary-border:             	darken($btn-primary-bg, 5%) !default;

$btn-success-color:              	#fff !default;
$btn-success-bg:                 	$brand-success !default;
$btn-success-border:             	darken($btn-success-bg, 5%) !default;

$btn-info-color:                	#fff !default;
$btn-info-bg:                    	$brand-info !default;
$btn-info-border:                	darken($btn-info-bg, 5%) !default;

$btn-warning-color:              	#fff !default;
$btn-warning-bg:                 	$brand-warning !default;
$btn-warning-border:             	darken($btn-warning-bg, 5%) !default;

$btn-danger-color:               	#fff !default;
$btn-danger-bg:                  	$brand-danger !default;
$btn-danger-border:              	darken($btn-danger-bg, 5%) !default;

$btn-link-disabled-color:        	#eee !default;

//##  Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-primary:  	  		 	"Open Sans", sans-serif !default;
$font-family-default:  	  		 	"Helvetica Neue", Helvetica, Arial, sans-serif !default;

$font-size-base:          		 	13px !default;
$font-size-normal:        		 	14px !default;
$font-size-large:         		 	ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         		 	ceil(($font-size-base * 0.85)) !default; // ~12px

$icon-size-base:          		 	13px !default;
$icon-size-normal:        		 	14px !default;
$icon-size-large:         		 	ceil(($icon-size-base * 1.25)) !default; // ~18px
$icon-size-small:         		 	ceil(($icon-size-base * 0.85)) !default; // ~12px

$font-size-h1:            		 	floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2:            			floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:            			ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:            			ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            			$font-size-base !default;
$font-size-h6:            			ceil(($font-size-base * 0.85)) !default; // ~12px

// Global text color on <body>
$text-color:            			#333333 !default;

// Global light text color on <body>
$text-light-color:     	 			#ffffff !default;

// Global muted text color on <body>
$text-muted-color:      			#444444 !default;

// Global textual link color.
$link-color:            			#444444 !default;

// Link hover color.
$link-hover-color:      			darken($link-color, 15%) !default;

$primary-link-color:                #65A0D0;

//== Forms Controls
$input-md-label-color:              #888888;
$input-md-label-opacity:      		0.8;
$input-md-input-border:        		#e5e5e5;
$input-md-label-opacity:      		1;
$input-md-label-font-size: 			13px;
$input-md-placeholder:	 			#999;
$input-md-color:		 			#333;
$input-md-focus-border:				$brand-success;
$input-md-checkbox-box-color:  		#666;
$input-md-checkbox-disabled-opacity:0.7;

$input-bg:                       	#fff !default;
$input-bg-disabled:              	#eeeeee !default;
$input-font-disabled:               #999 !default;

$input-color:                    	#333 !default;
$input-border:                   	#e5e5e5 !default;
$input-border-focus:             	#999 !default;

$input-color-placeholder:        	lighten($input-color, 50%) !default;
$input-icon-color:               	#999 !default;

//## Border radius
$general-border-radius:             2px !default;
$form-control-border-radius:        2px !default;
$portlet-border-radius:             2px !default;


//## Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar:            			9995 !default;
$zindex-navbar-above:      			9999 !default;
$zindex-dropdown:          			1000 !default;
$zindex-popover:           			1010 !default;
$zindex-tooltip:           			1030 !default;
$zindex-navbar-fixed:      			1030 !default;
$zindex-modal-background:  			10049 !default;
$zindex-modal:             			10050 !default;
$zindex-top:						10100 !default;

//## Media queries breakpoints
//
//Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs-min:              		480px !default;

// Small screen / tablet
$screen-sm-min:              		768px !default;

// Medium screen / desktop
$screen-md-min:              		992px !default;

// Large screen / wide desktop
$screen-lg-min:             	 	1200px !default;

// Large scree / wider desktop
$screen-lg-med:                     1260px !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              		($screen-sm-min - 1) !default;
$screen-sm-max:              		($screen-md-min - 1) !default;
$screen-md-max:              		($screen-lg-min - 1) !default;

//## Img path settings

$general-img-path:                  '../images/';


//##  Default Layout Settings
//
// Define layout element width and height.

$theme-type:                                                            'material-design';

// Header
$header-height:                                                         75px  !default; // header height on table and desktop view. default: 42px
$header-height-xs:                                                      ($header-height * 2) !default; // header height on mobile view
$header-height-mobile:                                                  46px  !default;

$header-logo-height:                                                    17px;
$header-menu-toggler-width:                                             20px;
$header-menu-toggler-height:                                            14px;
$header-sidebar-toggler-margin:                                         ($header-height - $header-menu-toggler-height - 1px) / 2 0 0 0 !default;
$header-responsive-toggler-margin:                                      ($header-height - $header-menu-toggler-height) / 2 14px 0 6px !default;
$header-logo-margin:                                                    ($header-height - $header-logo-height) / 2 10px 0 10px !default;  // adjsut logo's margin top. default: -1px

$header-page-actions-margin:                                            17px 0 15px 10px;

$header-top-menu-general-item-padding:                                  29px 12px 24px 12px !default;   // adjust top menu general elements padding. default: 15px 10px 7px 10px
$header-top-menu-general-item-padding-on-mobile:                        29px 8px 25px 8px !default;  // adjust top menu general elements padding mobile. default: 15px 10px 7px 10px

$header-top-menu-user-item-padding:                                     25px 10px 18px 10px !default; // adjust top menu user bar element padding. default: 6px 0px 6px 6px
$header-top-menu-user-item-padding-on-mobile:                           25px 6px 18px 6px !default;  // adjust top menu user bar element padding on mobile. default: 6px 0px 6px 4px
$header-top-menu-language-item-padding:                                 28px 12px 26px 12px !default;  // adjust top menu language bar element padding. default: 11px 1px 11px 5px
$header-top-menu-language-item-padding-on-mobile:                       28px 6px 26px 6px !default;  // adjust top menu language bar element padding. default: 11px 0px 11px 4px
$header-top-menu-general-item-badge-top:                                24px !default;  // adjust top menu general elements badge top position. default: 8px
$header-top-menu-icon-font-size:                                        16px !default;

$header-top-menu-user-font-size:                                        14px !default;
$header-top-menu-user-font-weight:                                      600 !default;
$header-top-menu-user-dropdown-link-font-size:                          14px !default;
$header-top-menu-user-dropdown-link-font-weight:                        300 !default;

$header-hor-menu-general-item-padding:                                  13px 10px !default;  // adjust hor menu elements padding top. default: 11px 10px
$header-hor-menu-submenu-item-padding:                                  9px 10px !default;  // adjust hor menu elements padding top. default: 7px 10px
$header-hor-menu-submenu-item-arrow-top:                                9px;  // adjust hor menu dropdown menu's arrow. default: 9px
$header-hor-menu-search-toggler-button-padding:                         15px 22px 15px 22px !default;  // adjust hor menu elements padding top. default: 13px 22px 11px 22px
$header-hor-menu-search-button-padding:                                 7px 20px !default;  // adjust hor menu elements padding top. default: 5px 20px
$header-hor-menu-search-input-margin:                                   7px 0 0 0 !default;  // adjust hor menu elements padding top. default: 5px 0 0 0

// Horizontal menu font
$header-hor-menu-default-link-font-size:                                14px !default;
$header-hor-menu-default-link-font-weight:                              normal !default;
$header-hor-menu-dropdown-menu-link-font-size:                          14px !default;
$header-hor-menu-dropdown-menu-link-font-weight:                        normal !default;
$header-hor-menu-mega-menu-header-font-size:                            15px !default;
$header-hor-menu-mega-menu-header-font-weight:                          normal !default;

// Sidebar
$sidebar-width:                                                         235px !default; // sidebar width. default:235px
$sidebar-collapsed-width:                                               54px  !default; // minimized/collapsed sidebar width. default:35px
$sidebar-collapsed-submenu-width-on-hover:                              ($sidebar-width - 1) !default; // sub menu width displayed on hover in collapsed sidebar mode. default:210px
$sidebar-logo-container-width:                                          ($sidebar-width + 30) !default; // logo container width. Normally same with sidebar-width.
$sidebar-logo-container-width-xs:                                       110px !default; // logo container width in mobile portrait view. default:110px.
$sidebar-logo-container-width-xxs:                                      100px !default; // logo container width in mobile landspace view. default:100px.

$sidebar-menu-link-font-size:                                           14px !default;
$sidebar-menu-link-font-weight:                                         400 !default;
$sidebar-menu-link-icon-font-size:                                      18px !default;
$sidebar-menu-link-arrow-font-size:                                     13px !default;
$sidebar-menu-sub-menu-link-icon-font-size:                             16px !default;

// Page content
$page-content-min-height:                                               600px !default; // default page content's min height. default:600px.

// Quick Sidebar
$quick-sidebar-width:                                                   320px !default;  // quick sidebar width. default: 245px;
$quick-sidebar-tab-item-padding:                                        15px 15px;       // quick sidebar item padding. default: 15px 15px;

$quick-sidebar-bg-color:                                                #21282e;
$quick-sidebar-font-color:                                              lighten($quick-sidebar-bg-color, 40%);

// Page footer
$page-footer-height:                                                    33px !default;

// Z-index master list
$zindex-header-fixed:                                                   9995 !default;
$zindex-header-static:                                                  9995 !default;
$zindex-quick-sidebar:                                                  9994 !default;
$zindex-quick-sidebar-full-height:                                      9996 !default;
$zindex-sidebar-fixed:                                                  10000 !default;
$zindex-footer-fixed:                                                   10000 !default;
$zindex-sidebar-submenu:                                                2000 !default;
$zindex-go-to-top:                                                      10001 !default;