/******************
Page Quick Sidebar
******************/

/***
Quick Sidebar Layout
***/

/* Page Portlet Fullscreen */

.page-portlet-fullscreen {
	.page-quick-sidebar-wrapper,
	.page-quick-sidebar-toggler {
		z-index: -1;
	}
}

/* Quick sidebar toggler */

.page-header {
	.mega-menu {
		.quick-sidebar-toggler {
			top: 7px;
			border: none;
			outline: none;
			padding: 5px 5px; 
			margin-left: 15px;
			position: relative;
			background: #45b6af;

			i {
				top: 0;
				color: #fff;

				.page-quick-sidebar-open &:before {
					content: "\e066";
				}
			}
		}
	}
}

/* Quick sidebar wrapper */

.page-quick-sidebar-wrapper {
	transition: right 0.3s;	
	z-index: $zindex-header-fixed;
	position: fixed;
	top: 0;
	bottom: 0;
	right: -$quick-sidebar-width;
	width: $quick-sidebar-width;

	.page-quick-sidebar-open & {
		transition: right 0.3s;	
		right: 0;
	}
}

/*** 
Quick Sidebar Toggler 
***/

.page-quick-sidebar-toggler {
	overflow: hidden;
	z-index: 99999;
	display: none;
	width: 28px;
	height: 27px;
	position: fixed;
	top: 5px; 
	right: 5px;
	text-align: center;
	padding-top: 6px;
	background: #21282e !important;

	.page-quick-sidebar-open & {
		display: inline-block;
	}

	> i {
		font-size: 17px;

		&:hover {
			color: #fff !important;
		}
	}
}

/***
Quick Sidebar Content
***/

.page-quick-sidebar-wrapper {
	right: -$quick-sidebar-width;
	overflow: hidden;

	.page-quick-sidebar {
		/* Quick sidebar tabs */
		.nav-justified {
			margin: 0;
			padding: 0;

			> li {
				display: table-cell !important;
				width: 1%  !important;

				> a {
					border: 0;
					height: auto;
					font-size: 15px;
					padding: 45px 15px 8px;
					text-transform: uppercase;

                    @include border-radius(0);

					> .badge {
						position: absolute;
						top: 45px;
						right: 3px;
					}

					&:hover {
						border: 0;
					}
				}

				.dropdown-menu {	  
	               	margin-top: 8px;                   
	                margin-right: 20px;  

	                &:before {
						position: absolute;
						top: -7px;
						right: 19px;
						display: inline-block !important;
						border-right: 7px solid transparent;
						border-left: 7px solid transparent;
						border-bottom-color: rgba(0, 0, 0, 0.2);
						content: '';
					}   

					&:after {
						position: absolute;
						top: -6px;
						right: 20px;
						display: inline-block !important;
						border-right: 6px solid transparent;
						border-bottom: 6px solid #fff;
						border-left: 6px solid transparent;
						content: '';
					}
	            }
			}
		}

		/* Quick sidebar tabs content */
		.nav-justified {
			> .tab-content {
				margin: 0;
				padding: 0;
			}
		}

		/* Quick sidebar general list heading */
		.list-heading {
			font-size: 16px;
			margin: 10px 10px;
		}

		/* Quick sidebar general list-items */
		.list-items {
			margin: 0;
			padding: 0;
			list-style: none;

			> li {
				margin: 0;
		        padding: 15px;
		        background: none;
		        border-bottom-width: 1px;
		        border-bottom-style: solid;

	            &:last-child {
		            border-bottom: 0;
		        }

		        &:hover { 
		        }
	    	}

	    	&.borderless {
	    		li {
	    			border: 0;  
		        }
	    	}
		}

		/* Inner content */
		.inner-content {
			margin: 10px 10px;
		}

		/* Quick sidebar list */
		.page-quick-sidebar-list {
			position: absolute !important;
			width: $quick-sidebar-width !important; 
			transition: margin 0.3s; 
		}

		/* Quick sidebar list item */
		.page-quick-sidebar-item {
			width: $quick-sidebar-width;
			position: absolute !important;
			width: $quick-sidebar-width  !important; 
			transition: margin 0.3s; 
			margin-left: $quick-sidebar-width; 

			.page-quick-sidebar-nav {
				padding: 15px 10px 0px 10px;

				.page-quick-sidebar-back-to-list {
	                vertical-align: middle;  
	                display: inline-block;
	                font-size: 14px;

	                > i {
	                    font-size: 17px;
	                    line-height: 17px;
	                    vertical-align: top;
	                    margin-right: 3px;
	                }
	            }
			}            
		}	

		/* Quick sidebar list item shown */
		.page-quick-sidebar-content-item-shown {
			.page-quick-sidebar-list {
				transition: margin 0.3s; 
				margin-left: - $quick-sidebar-width; 

				.slimScrollBar,
				.slimScrollRail {
					display: none !important;  
				}
			}

			.page-quick-sidebar-item {
				transition: margin 0.3s;
				margin-left: 0;  
			} 
		}

		/* Quick sidebar chat */
		.page-quick-sidebar-chat {
			.page-quick-sidebar-chat-users {
				padding: 10px 0;
				position: relative;
						
				.media-list {
					.media {
						padding: $quick-sidebar-tab-item-padding;

						.media-object {							
				            border-radius: 50% !important;
							width:  $quick-sidebar-width/7;
							opacity: 0.8;
	           				filter: alpha(opacity=80);
							float: left;
							margin-right: 10px;

							@include clearfix();
						}

						&:hover {
							cursor: pointer;

							.media-object {
								opacity: 1;
		                		filter: alpha(opacity=100);
							}
						}

						.media-body {

							.media-heading {
								margin: 5px 0 0 0;
								font-size: 14px;
							}

							.media-heading-sub {
								font-size: 11px;
								text-transform: uppercase;
							}

							.media-heading-small {
								font-size: 10px;								
							}
						}

						.media-status {
							margin-top: 10px; 
							right: 10px;
							position: absolute;
							display: inline-block;
						}
					}
				}
			}

			.page-quick-sidebar-chat-user {

				.page-quick-sidebar-chat-user-messages { 
					padding: 0px 10px;
					position: relative;

					.post {
						transition: display 0.3s;
				        padding: 5px 0;
				        margin: 10px auto;
				        font-size: 13px;

				        .body {
				        	color: #c3c3c3;
				            display: block;
				        }
        
				        .avatar {
				            width:$quick-sidebar-width/7;
				            border-radius: 50% !important;
				        }

				        &.in .avatar {
				            float: left;
				            margin-right: 10px;
				        }

				        &.out .avatar {
				            float: right;
				            margin-left: 10px;
				        }

				        .name {
				            font-size: 12px;
				            font-weight: 300;
				        }
				        
				        .datetime {
				            font-size: 12px;
				            font-weight: 300;
				        }
				       
				        .message {			
				            display: block;
				            padding: 5px;
				            position: relative;
				        }

				        &.in {
				        	.message {
				            	text-align: left;
				            	margin-left: 55px;
				      
				            	.arrow {
				                	display: block;
				                	position: absolute;
				                	top: 9px;
				                	left: -6px;
				                	width: 0;
				                	height: 0;
				                	border-top: 6px solid transparent;
				                	border-bottom: 6px solid transparent;
				                	border-right-width: 6px;
				                	border-right-style: solid;
				                }
				            }
				        }

				        &.out {
				            .message {
				                margin-right: 55px;
				                text-align: right;

				                .arrow {
				                    display: block;
				                    position: absolute;
				                    top: 9px;
				                    right: -6px;
				                    border-top: 6px solid transparent;
				                    border-bottom: 6px solid transparent;
				                    border-left-width: 6px;
				                	border-left-style: solid;
				                }  
				            }
				  
				            .name, 
				            .datetime {
				                text-align: right;
				            }
				        }
				    }
				}

				.page-quick-sidebar-chat-user-form {
					padding: 20px 10px 15px 10px;

					.input-group {
						.form-control {
							font-size: 13px;   
						}
					}
				}
			}
		}

		/* Quick sidebar alerts */
		.page-quick-sidebar-alerts {
			
			.page-quick-sidebar-alerts-list {
				padding: 10px 0;
				position: relative;

	            .feeds {
	                li {	                	
	                    .label {
	                        margin-top: 5px;
	                    }

	                    .desc {
	                        padding: 0;
	                    }
	                }
	            }
	        }
		}

		/* Quick sidebar settings */
		.page-quick-sidebar-settings {

			.page-quick-sidebar-settings-list {
				padding: 10px 0;
				position: relative;

				.list-items {
					li {
						.bootstrap-switch {
							margin-top: -3px;
							float: right;
							border: 0;
							min-width: 59px;
						}	

						.form-control {
							width: 75px !important;							
							padding: 4px 4px !important;
							float: right;
							border: 0;
							margin-top: -4px;
						}

						select.form-control {   							
							padding: 4px 0px !important;
						}
					}
				}
			}				
		}
	}
}



    /***
    Quick Sidebar Layout
    ***/

    .page-quick-sidebar-wrapper {
        background: $quick-sidebar-bg-color;

        .page-quick-sidebar {
            background: $quick-sidebar-bg-color; 
        }
    }

    /*** 
    Quick Sidebar Toggler 
    ***/

    .page-quick-sidebar-toggler {
        background: lighten($quick-sidebar-bg-color, 10%);

        &:hover {
            background: darken(lighten($quick-sidebar-bg-color, 10%), 3%);            
        }

        > i {
            color: lighten($quick-sidebar-font-color, 10%);
        }
    }

    /***
    Quick Sidebar Content
    ***/

    .page-quick-sidebar-wrapper {
        color: lighten($quick-sidebar-font-color, 10%);
        
        .page-quick-sidebar {

            /* Quick sidebar tabs */
            .nav-justified {
                > li {

                    > a {
                    	background: inherit;
                        color: lighten($quick-sidebar-font-color, 7%);
                        border-bottom: 3px solid rgba(243,86,93,.3);

                        &:hover {
                        	border-bottom: 3px solid #f3565d;
                        }
                    }

                    &.open {
                        > a {
                        	background: inherit;
                            color: lighten($quick-sidebar-font-color, 7%);
                        }
                    }

                   
                    &.active {
                        > a {
                            border: 0;
	                        border-bottom: 3px solid #f3565d;
                        }
                    }

                    .dropdown-menu {                    
                        border: 0;
                        background: lighten($quick-sidebar-bg-color, 10%); 
                        box-shadow: 5px 5px rgba(lighten($quick-sidebar-bg-color, 30%), 0.1);

                        &:before {                        
                            border-bottom: 7px solid lighten($quick-sidebar-bg-color, 10%);
                        }

                        &:after {                        
                            border-bottom: 7px solid lighten($quick-sidebar-bg-color, 10%);
                        }

                        > li {
                            > a {
                        		padding: 10px 15px;
                                color: lighten($quick-sidebar-font-color, 10%); 

                                > i {
                                    color: lighten($quick-sidebar-font-color, 8%); 
                                }

                                &:hover {
                                    background: lighten($quick-sidebar-bg-color, 13%); 
                                    color: lighten($quick-sidebar-font-color, 10%); 

                                    > i {
                                        color: lighten($quick-sidebar-font-color, 11%); 
                                    }
                                }
                            }

                            &.active {
                                > a {
                                    background: lighten($quick-sidebar-bg-color, 11%); 
                                    color: lighten($quick-sidebar-font-color, 10%); 
                                }
                            }

                            &.divider {
                                background-color: lighten($quick-sidebar-bg-color, 13%); 
                            }
                        }
                    }
                }
            }

            /* Quick sidebar general list heading */
            .list-heading {
                color: darken($quick-sidebar-font-color, 5%); 
            }

            /* Quick sidebar general list-items */
            .list-items {
                margin: 0;
                padding: 0;
                list-style: none;

                > li {
                    border-bottom-color: lighten($quick-sidebar-bg-color, 3%); 

                    &:hover { 
                        background: lighten($quick-sidebar-bg-color, 3%); 
                    }
                }
            }
        }

        .page-quick-sidebar-item {
              
            /* back to list */
            .page-quick-sidebar-nav {
                .page-quick-sidebar-back-to-list {
                    color: lighten($quick-sidebar-font-color, 7%);

                    &:hover {
                    	text-decoration: none;
                    }
                }
            }
        }

        /* Quick sidebar chat */
        .page-quick-sidebar-chat { 

            // chat users
            .page-quick-sidebar-chat-users {
                .media-list {
                    .media {
                        .media-body {
                            .media-heading-sub {
                                color: darken($quick-sidebar-font-color, 8%); 
                            }

                            .media-heading-small {
                                color: darken($quick-sidebar-font-color, 12%);   
                            }
                        }
                    }
                }
            }

            // user chat
            .page-quick-sidebar-chat-user {
                .page-quick-sidebar-chat-user-messages {
                    .post {
                        .name {
                            color: lighten($quick-sidebar-font-color, 3%); 
                        }

                        .datetime {
                            color: lighten($quick-sidebar-font-color, 3%); 
                        }

                        .message {  
                            color: lighten($quick-sidebar-font-color, 7%); 
                            background: lighten($quick-sidebar-bg-color, 10%); 
                        }

                        &.in {
                            .message {
                                .arrow {
                                    border-right-color: lighten($quick-sidebar-bg-color, 10%); 
                                }
                            }
                        }

                        &.out {
                            .message {
                                .arrow {
                                    border-left-color: lighten($quick-sidebar-bg-color, 10%); 
                                }
                            }
                        }
                    }
                }
            }
        }

        /* Quick sidebar alerts */

        .page-quick-sidebar-alerts {
            .page-quick-sidebar-alerts-list {
                .feeds {
                    li {
                        a {
                            color: lighten($quick-sidebar-font-color, 1%);

                            .desc {
                                text-decoration: underline;
                            }
                        }

                        .desc {
                            color: darken($quick-sidebar-font-color, 1%);
                        }

                        .date {
                            color: darken($quick-sidebar-font-color, 12%);
                        }
                    }
                }
            }
        }
    }


    