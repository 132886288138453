//## Main Layout CSS Builder

@import 'layout/_print'; // print layout

@import 'layout/_header'; // page header
@import 'layout/_page-container'; // page main container
@import 'layout/_quick-sidebar'; // page quick sidebar
@import 'layout/_sidebar';  // page sidebar
@import 'layout/_content'; // page consnde
@import 'layout/_footer';   // page footer

/*@import 'layout/_theme-panel';*/  // theme customizer panel

/***
Page Loading
***/

.page-on-load {
	background: #fefefe;

	.page-header,
	.page-container,
	.page-footer,
	> .clearfix {
		display: none;
		transition: all 2s;
	}
}