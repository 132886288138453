// Theme Colors Settigns

// Main settings
$theme-name:                                                    		"default";
$brand-main-color:                                               		#5b9bd1 !default;
$brand-main-font-color:													#ffffff !default;

// Body and header
$bg-color:                                                         		darken(#F0F2F7, 2%) !default;
$header-bg-color:														#FFFFFF !default;
$header-top-shadow:                                                     0px 1px 10px 0px rgba(50, 50, 50, 0.2);
$header-mobile-menu-border:                                             lighten($bg-color, 7%);

// Header search
$header-search-bg-color:                                        		$header-bg-color !default;
$header-search-font-color:                                      		darken(#F9FAFB, 14%) !default;
$header-search-placeholder-font-color:                          		darken($header-search-font-color, 1%) !default;

//Sidebar toggler
$header-toggler-img:                                                   'sidebar-toggle-light.png';

// Top menu
$header-top-menu-bg-hover-color:                                		lighten(#F6F7FA, 1%) !default;
$header-top-menu-bg-hover-color-on-dropdown-open:               		lighten(#F6F7FA, 1%) !default;
$header-top-menu-badge-bg-color:                                		$brand-main-color;
$header-top-menu-badge-font-color:                              		#ffffff;
$header-top-menu-icon-font-color:                               		#C0CDDC !default;

// Top menu user bar
$header-top-menu-user-font-color:                                       #7FB0DA !default;
$header-top-menu-user-bg-color:                                 		none !default;

//begin: Top Menu Extended Dropdowns
$header-top-menu-extended-separator-bg-color: 							darken(#f8f9fa, 5%);
$header-top-menu-extended-dropdown-item-bg-color-on-hover:              #f8f9fa;
$header-top-menu-extended-dropdown-item-border-color:                   #EFF2F6;
$header-top-menu-extended-dropdown-item-font-color:						#888888;

$header-top-menu-extended-dropdown-header-bg-color:						darken($bg-color, 2%);
$header-top-menu-extended-dropdown-border-color:                        darken($header-top-menu-extended-dropdown-header-bg-color, 2%);
$header-top-menu-extended-dropdown-header-font-color:					darken(#6f949c, 5%);

$header-top-menu-inbox-dropdown-from-font-color:						#5b9bd1;

$header-top-menu-task-dropdown-progress-bg-color:						#dfe2e9;

$header-top-menu-notification-time-bg-color:							lighten(#eee, 1%);

// Top Menu Notifications
$header-hor-menu-bg-color:                                              #3D4A53;
$header-top-menu-dropdown-dark-bg-color: 								lighten($header-hor-menu-bg-color, 10%);
$header-top-menu-dropdown-dark-header-bg-color:							lighten($header-hor-menu-bg-color, 2%);
$header-top-menu-dropdown-dark-header-font-color:						lighten($header-top-menu-dropdown-dark-header-bg-color, 50%);

$header-top-menu-dropdown-dark-item-font-color:							lighten($header-top-menu-dropdown-dark-header-bg-color, 55%);
$header-top-menu-dropdown-dark-item-border-color:						lighten($header-top-menu-dropdown-dark-bg-color, 6%);
$header-top-menu-dropdown-dark-item-icon-color:							lighten($header-top-menu-dropdown-dark-header-bg-color, 45%);
$header-top-menu-dropdown-dark-item-bg-color-on-hover:					lighten($header-top-menu-dropdown-dark-bg-color, 4%);

$header-top-menu-dropdown-dark-default-menu-divider:					$header-top-menu-dropdown-dark-item-border-color;
$header-top-menu-dropdown-dark-notification-time-bg-color:				darken($header-top-menu-dropdown-dark-bg-color, 5%);
//end: Top Menu Extended Dropdowns

// Top menu dropdown
$header-top-menu-dropdown-head-bg-color:                                lighten(#3D4A53, 35%);
$header-top-menu-dropdown-head-font-color:                              lighten(#3D4A53, 95%);
$header-top-menu-dropdown-border-color:                                 lighten(#3D4A53, 60%);

// Sidebar menu
$sidebar-bg-color:                                                      #ffffff !default;

$sidebar-menu-heading-font-color:                                       #5C9ACF !default;

$sidebar-menu-link-font-color:                                  		darken(#768da2, 20%) !default;
$sidebar-menu-link-font-color-on-hover:                         		$brand-main-color !default;
$sidebar-menu-link-font-color-on-active:                        		$brand-main-color !default;

$sidebar-menu-link-icon-font-color:                             		darken(#b7c9d6, 10%) !default;
$sidebar-menu-link-icon-font-color-on-active:                   		$brand-main-color !default;
$sidebar-menu-link-icon-font-color-on-hover:                            $brand-main-color !default;

$sidebar-menu-link-bg-color-on-hover:                           		darken(#f9fbfc, 2%) !default;
$sidebar-menu-link-bg-color-on-active:                          		darken(#f9fbfc, 2%) !default;

$sidebar-menu-arrow-color:                                      		lighten($sidebar-menu-link-icon-font-color, 3%) !default;
$sidebar-menu-arrow-color-on-active:                            		lighten($sidebar-menu-link-icon-font-color-on-active, 3%) !default;
$sidebar-menu-arrow-color-on-hover:                                     lighten($sidebar-menu-link-icon-font-color-on-hover, 3%) !default;

$sidebar-menu-sub-menu-link-font-color:                         		$sidebar-menu-link-font-color !default;
$sidebar-menu-sub-menu-link-icon-font-color:                    		$sidebar-menu-link-icon-font-color !default;

$sidebar-menu-sub-menu-link-bg-color-on-hover:                  		$sidebar-menu-link-bg-color-on-hover !default;
$sidebar-menu-sub-menu-link-font-color-on-hover:                  		$sidebar-menu-link-font-color-on-hover !default;
$sidebar-menu-sub-menu-link-icon-font-color-on-hover:                   $sidebar-menu-link-icon-font-color-on-hover !default;

$sidebar-menu-sub-menu-box-shadow-color:                                darken($sidebar-menu-link-bg-color-on-hover, 30%) !default;
$sidebar-menu-hover-sub-menu-bg-color:									#FFFFFF !default;

// Sidebar for mobile
$mobile-sidebar-menu-bg-color:                           				darken($bg-color, 7%) !default;
$mobile-sidebar-menu-link-bg-color-on-hover:             				darken($bg-color, 4%) !default;

// Footer
$footer-fluid-font-color:                                               darken($sidebar-menu-link-font-color, 10%);
$footer-default-font-color:                                     		darken(#3D4A53, 30%) !default;
$footer-default-go-top-bg-color:                                		darken(#94A0B2, 10%) !default;
$footer-default-go-top-icon-font-color:                          		lighten(#94A0B2, 40%) !default;
$footer-fixed-bg-color: 												$bg-color !default;

@import '_base';